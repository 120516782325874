import React, { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../scss/HomePageIntro.scss';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';

export default function HomePageIntro() {
  const data = useStaticQuery(graphql`
    query MyQuery10 {
      allContentfulTextBlock {
        edges {
          node {
            bodyText {
              raw
            }
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const desiredTextBlock = data.allContentfulTextBlock.edges.find(
    (edge) => edge.node.id === '03ffa85d-0dbb-523f-94f9-e63eaf9eb86a'
  );

  if (!desiredTextBlock) {
    return null;
  }

  const introData = desiredTextBlock.node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 400,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <title>Luxury Customer Analysis for Investors and Leaders </title>
        <meta name="description" content="Luxury Customer Analysis - Fund Managers De-Risk Investment into Luxury Companies and Luxury Leaders Acquire Customers and Enter New Markets " />
        <meta name="keywords" content="Luxury Customer Analysis, Luxury Data Analyst, Luxury Customer Data Analytics" />
        <h1>Luxury’s Top Leaders and Investors Dominate with Knowledge and Power</h1>
        <h2>scientia est potential -knowledge is power</h2>
      </Helmet>
      <div className="contentFeatureIntro">
        <div className="textContainerIntro">
          <p className="titleIntro">{introData.title}</p>
          <div className="bodyIntro">
            {documentToReactComponents(JSON.parse(introData.bodyText.raw))}
          </div>
        </div>
        <div className={'arrowContainers'}>
          <img
            className={'arrows'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
        </div>
      </div>
    </>
  );
}

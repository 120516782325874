import React, { useEffect } from "react";
import "../scss/HomePageEstCentury.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from 'gatsby-plugin-image';

export default function HomePageEstCentury() {
  const data = useStaticQuery(graphql`
  query MyQuery97 {
    allContentfulImage(filter:{ id: {eq:"6102c1ea-7853-5ca3-a9a8-37fc4df6677b"}}) {
      edges {
        node {
          name
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const imageSrc = data.allContentfulImage.edges[0].node.image.file.url;

  return (
    <div className="contentFeatureEstCentury">
      <div data-aos="fade-up" className="boardTextContainerEstCentury fade-in">
        <div className={"displayBoardContainerEstCentury"}>
          <div className="displayBoardEstCentury"></div>
        </div>
      </div>

      <div className={"textContainerEstCentury"}></div>
      <div className={"imageContainerEstCentury"}>
        {/* <img
          // data-aos="fade-up"
          className={"bigImageEstCentury"}
          src={imageSrc}
          alt="EstCentury"
        /> */}
        <StaticImage className={"bigImageEstCentury"} 
        src="https://images.ctfassets.net/x5jqyuhgi4rx/hbzkzu0hrSKSlgcMTeuSY/444e25c6cda30275c7df8e171152683d/est21centuryy.webp"
        alt="EstCentury"/>
      </div>
    </div>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "../scss/BrandCarousel.scss";

export default function BrandCarousel() {
  const data = useStaticQuery(graphql`
    query MyQuery12 {
      allContentfulBrandCarousel {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const classList = ["e", "f", "l", "j", "h", "b", "t"];

  const brandImages = data.allContentfulBrandCarousel.edges.flatMap(({ node }) =>
    node.image.map((image, index) => (
      <img
        key={`${node.id}-${index}`}
        className={`brandImage ${classList[index]}`}
        src={image.image.file.url}
        alt={image.altText}
      />
    ))
  );

  return (
    <div className="logos">
      <div className="title">Brands I've worked with:</div>
      <div className="logos-slide">
        {brandImages.slice(0, 7)} {/* Display the first 7 images */}
      </div>
      <div className="logos-slide">
        {brandImages.slice(0, 7)} {/* Display the first 7 images */}
      </div>
      {/* <div className="subtitleB">Customer Case Studies</div> */}
    </div>
  );
}

import React, { useEffect } from "react";
import "../scss/HomePageFeature3.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"

export default function HomePageFeature3() {
  const data = useStaticQuery(graphql`
    query MyQuery15 {
      allContentfulFlexBoxStyle(filter: { id: { eq: "14305cb5-bc4d-5e48-96ce-5531382a6b5a" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const options = {
    renderText: (text) => {
      const sentences = text.split('. ');
      return sentences.map((sentence, index) => (
        <React.Fragment key={index}>
          {sentence}
          <br />
        </React.Fragment>
      ));
    },
  };

  if (data.allContentfulFlexBoxStyle.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const featureData = data.allContentfulFlexBoxStyle.edges[0].node;

  return (
    <>
      <Helmet>
        <h2>Grow in new luxury markets</h2>
      </Helmet>
    <div className="contentFeature3">
      <div className={"imageContainer3"}>
        {/* <img
          data-aos="fade-up"
          className={"bigImage3"}
          alt={""}
          key={""}
          src={featureData.image.image.file.url}
        /> */}
        <StaticImage data-aos="fade-right" className={"bigImage3"} src="https://images.ctfassets.net/x5jqyuhgi4rx/1AWPdcQ6iurKpv0MDEslog/029effa319a1c797a497ddd5eef592d0/fashiontechreporthomepage.png" layout="fullWidth" alt={featureData.image.altText}/>
      </div>

      <div className="boardTextContainer3">
        <div data-aos="fade-up" className="boardTextAnimationContainer3">
          <div className={"displayBoardContainer3"}>
            <div className={"displayBoard3"}></div>
          </div>

          <div className={"textContainer3"}>
            <p className={"title3"}>
              {featureData.title}
            </p>
            <p className={"categoryName3"}>
              {featureData.subTitle}
            </p>
            <style>{`
              .bodyText3 br {
                display: block;
                content: "";
                margin-bottom: 20px;
              }
            `}</style>
            <p className={"bodyText3"}>
              {documentToReactComponents(
                JSON.parse(featureData.bodyText.raw),
                options
              )}
            </p>
            <div className={"callToAction3"}>
              <Link
                to="/luxury-circulareconomy/"
                style={{ textDecoration: "underline", color: "white", fontFamily: "'AriaText-SemiBold', sans-serif" }}
              >
                {featureData.callToAction}
              </Link>
            </div>
            <style>{`
              callToAction3:visited {
                color: white;
              }
            `}</style>
          </div>
        </div>
      </div>

      <div className={"displayBarContainer3"}>
        <div data-aos="fade-up" className={"displayBar3"}></div>
      </div>
    </div>
    </>
  );
}


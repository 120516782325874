import React, { useEffect } from "react";
import "../scss/HomePageFeature2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"

export default function HomePageFeature2() {
  const data = useStaticQuery(graphql`
    query MyQuery14 {
      allContentfulFlexBoxStyle(filter: { id: { eq: "1aeef802-0cfd-5b36-9a29-7058539aab47" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  if (data.allContentfulFlexBoxStyle.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const featureData = data.allContentfulFlexBoxStyle.edges[0].node;

  return (
    <>
      <Helmet>
        <h2>A Modern Heritage</h2>
      </Helmet>
    <div className="contentFeature2">
      <div className="imageContainer2">
        {/* <img
          data-aos="fade-right"
          className={"bigImage2"}
          alt={""}
          key={""}
          src={featureData.image.image.file.url}
        /> */}
        <StaticImage data-aos="fade-right" className={"bigImage2"} src="https://images.ctfassets.net/x5jqyuhgi4rx/6mELwy7LBTXJvjBRgPPZAS/141666c011ea2053a26c938107e039ed/nuherprevious.png" layout="fullWidth" alt={featureData.image.altText}/>
      </div>

      <div className={"textContainer2"}>
        <p data-aos="fade-left" className={"categoryName2"}>
          {featureData.subTitle}
        </p>
        <p data-aos="fade-left" className={"title2"}>
          {featureData.title}
        </p>
        <style>{`
              .bodyText2 br {
                display: block;
                margin: 1em 0;
                content: "";
              }
            `}</style>
        <p data-aos="fade-left" className={"bodyText2"}>
          {documentToReactComponents(
            JSON.parse(featureData.bodyText.raw),
            options
          )}
        </p>
        <div className={"callToAction2"}>
          <Link
            to="/nuheritage/"
            style={{ textDecoration: "underline", color: "#525151", fontFamily: "'AriaText-SemiBold', sans-serif" }}
          >
            {featureData.callToAction}
          </Link>
        </div>
        <style>{`
          callToAction2:visited {
            color: #525151;
          }
        `}</style>
      </div>
    </div>
    </>
  );
}


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../scss/shareprice.scss';

const StockPrice = () => {
  const [prices, setPrices] = useState({});
  const [priceText, setPriceText] = useState({});
  const symbolsWithNames = [
    { symbol: 'LVMHF', name: 'LVMH' },
    // { symbol: 'RMS.PA', name: 'Hermes' },
    // { symbol: 'CFR', name: 'Richemont' },
    // { symbol: 'KER.PA', name: 'Kering'},
    // Add more symbols and names as needed
  ];

  useEffect(() => {
    const apiKey = 'GGM0QZ43H88A4ICK';

    const fetchStockData = async (symbol) => {
      try {
        const response = await axios.get(
          `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${symbol}&apikey=${apiKey}`
        );

        const data = response.data['Time Series (Daily)'];

        if (data) {
          // Get the latest date's data
          const latestDate = Object.keys(data)[0];
          const latestPrice = data[latestDate]['4. close'];
          setPrices((prevPrices) => ({
            ...prevPrices,
            [symbol]: { name: getNameBySymbol(symbol), price: latestPrice },
          }));

          // Update the price text
          setPriceText((prevPriceText) => ({
            ...prevPriceText,
            [symbol]: `${getNameBySymbol(symbol)} Share Price: $${parseFloat(latestPrice).toFixed(2)}`,
          }));
        } else {
          console.error(`No data received from Alpha Vantage for ${symbol}.`);
        }
      } catch (error) {
        console.error(`Error fetching data from Alpha Vantage for ${symbol}:`, error);
      }
    };

    // Fetch data for each symbol initially
    symbolsWithNames.forEach((symbolObj) => {
      fetchStockData(symbolObj.symbol);
    });

    // Set up a timer to fetch data every hour (3600000 milliseconds = 1 hour)
    const fetchInterval = setInterval(() => {
      symbolsWithNames.forEach((symbolObj) => {
        fetchStockData(symbolObj.symbol);
      });
    }, 3600000);

    // Cleanup the timer when the component unmounts
    return () => clearInterval(fetchInterval);
  }, []);

  const getNameBySymbol = (symbol) => {
    const matchingSymbolObj = symbolsWithNames.find((obj) => obj.symbol === symbol);
    return matchingSymbolObj ? matchingSymbolObj.name : symbol;
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 800) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      {Object.keys(prices).map((symbol) => (
        <p key={symbol} className={`stock-price ${isVisible ? '' : 'fade-out'}`}>
          <span className="price-text">{priceText[symbol]}</span>
        </p>
      ))}
    </div>
  );
};

export default StockPrice;

import * as React from "react"
// import "./index.css"

import ValueProposition from "../components/HomePage/ValueProposition"
import StockPrice from "../components/HomePage/shareprice"
import HomePageFeature2 from "../components/HomePage/HomePageFeature2"
import HomePageFeature3 from "../components/HomePage/HomePageFeature3"
import HomePageFeature1 from "../components/HomePage/HomePageFeature1"
import HomePageMarque from "../components/HomePage/HomePageMarque"
import HomePageEstCentury from "../components/HomePage/HomePageEstCentury"
import HomePageIntro from "../components/HomePage/HomePageIntro"
import HomePageQuote from "../components/HomePage/HomePageQuote"
import HeroImage from "../components/HomePage/HeroImage"
import HomePageScientiaText from "../components/HomePage/HomePageScientiaText"
import Layout from "../components/Layout"
import BrandCarousel from "../components/HomePage/BrandCarousel"
import HomePageLatin from "../components/HomePage/HomePageLatin"
import WhatIDoBar from "../components/HomePage/WhatIDoBar"
import ValueProp from "../components/HomePage/ValueProp"

function IndexPage({ data }) {
  return (
    <Layout>
      <HeroImage />
      <ValueProp />
      <StockPrice/>
      <HomePageMarque/>
      <HomePageEstCentury/>
      <HomePageIntro/>
      <BrandCarousel />
      <HomePageLatin />
      <HomePageScientiaText />
      <HomePageQuote/>
      <HomePageFeature1 />
      <WhatIDoBar />
      <HomePageFeature2 />
      <HomePageFeature3 />
    </Layout>
    
  )
}

export default IndexPage
import React, { useEffect } from "react";
import "../scss/HomePageMarque.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image";

export default function HomePageMarque() {
  const data = useStaticQuery(graphql`
    query MyQuery9 {
      allContentfulImage(filter: { name: { eq: "Marque Black Texture" } }) {
        edges {
          node {
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const imageSrc = data.allContentfulImage.edges[0].node.image.file.url;

  return (
    <div className="contentFeatureMarque">
      <div data-aos="fade-up" className="boardTextContainerMarque fade-in">
        <div className={"displayBoardContainerMarque"}>
          <div className="displayBoardMarque"></div>
        </div>
      </div>

      <div className={"textContainerMarque"}></div>
      <div className={"imageContainerMarque"}>
        <StaticImage
          // data-aos="fade-up"
          className={"bigImageMarque"}
          src="https://images.ctfassets.net/x5jqyuhgi4rx/4RlMgezCl00kXf6yViJP6m/de85ff7ebd0402d51b778723fb4454ff/marque-black-texture.webp"
          alt="marque"
        />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import "../scss/HomePageLatin.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from 'gatsby-plugin-image';

export default function HomePageLatin() {
  const data = useStaticQuery(graphql`
  query MyQuery95 {
    allContentfulImage(filter:{ id: {eq:"70e13341-80b0-5c99-a242-96a7e461a7ff"}}) {
      edges {
        node {
          name
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const imageSrc = data.allContentfulImage.edges[0].node.image.file.url;

  return (
    <div className="contentFeatureLatin">
      <div data-aos="fade-up" className="boardTextContainerLatin fade-in">
        <div className={"displayBoardContainerLatin"}>
          <div className="displayBoardLatin"></div>
        </div>
      </div>

      <div className={"textContainerLatin"}></div>
      <div className={"imageContainerLatin"}>
        {/* <img
          // data-aos="fade-up"
          className={"bigImageLatin"}
          src={imageSrc}
          alt="Latin"
        /> */}
        <StaticImage className={"bigImageLatin"} 
        src="https://images.ctfassets.net/x5jqyuhgi4rx/5KGqK3Jzwjq7kTMViEwEBk/0f3de57a5663654aff76d56bfbea6c85/latinn.webp"
        alt="Latin"/>
      </div>
    </div>
  );
}

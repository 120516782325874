import React, { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../scss/HomePageScientiaText.scss';
import arrow from './arrow_down.png';

export default function HomePageScientiaText() {
  const data = useStaticQuery(graphql`
    query MyQuery106 {
      allContentfulTextBlock(filter: { id: { eq: "712c5713-24b4-5ae7-b364-a8e4dcf1ef34" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const desiredTextBlock = data.allContentfulTextBlock.edges.find(
    (edge) => edge.node.id === '712c5713-24b4-5ae7-b364-a8e4dcf1ef34'
  );

  if (!desiredTextBlock) {
    return null;
  }

  const ScientiaTextData = desiredTextBlock.node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 400,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <div className="contentFeatureScientiaText">
      <div className="textContainerScientiaText">
        {/* <p className="titleScientiaText">{ScientiaTextData.title}</p> */}
        <div className="bodyScientiaText">
          {documentToReactComponents(JSON.parse(ScientiaTextData.bodyText.raw))}
        </div>
      </div>
      <div className={'arrowContainerScientiaText'}>
        <img
          className={'arrowScientiaText'}
          src={arrow}
          alt="arrow"
          onClick={handleArrowClick} // Attach the click handler here
        />
      </div>
    </div>
  );
}

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../scss/ValueProp.scss';
import { StaticImage } from "gatsby-plugin-image"

export default function ValueProp() {
  const data = useStaticQuery(graphql`
    query MyQuery800 {
      allContentfulValuePropositionBar (filter:{id:{eq:"b43b077c-cbda-5385-8b64-2ba1dff7d4d7"}}){
        edges {
          node {
            bodyText
            bodyText2
            bodyText3
            title
            title2
            title3
            image3 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image2 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;

  return (
    <div className="valuePropDisplayBar">
      <div className="vpbar">
        <div className="vpitems">
          <div className="vpitem">
            {/* <img className="vpimage" src={image.image.file.url} alt={image.altText} /> */}
            <StaticImage className={"vpimage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/4X3kBSv0Gb0ui7vs8rJ1jj/73ad8b3c1f12bfe14d9e5ac690bde857/first_value_prop_latest.png" layout="fullWidth" alt={image.altText}/>
            {/* <div className="vpcontent">
            <div className="vptitle">
              <span className="larger-text"><strong>18</strong></span> years
            </div>
            <div className="vpsentence">{bodyText}</div>
          </div> */}
          </div>
          <div className="vpitem">
          <StaticImage className={"vpimage2"} src="https://images.ctfassets.net/x5jqyuhgi4rx/5lkVzkEMFThQZTZKQTSXWH/e4ab2b95c42e2c3495bed09ee5c26f50/second_value_prop_latest.png" layout="fullWidth" alt={image2.altText}/>
            {/* <div className="vpcontent2">
              <div className="vptitle2"><strong>{title2}</strong></div>
              <div className="vpsentence2">{bodyText2}</div>
            </div> */}
          </div>
          <div className="vpitem">
            {/* <img className="vpimage3" src={image3.image.file.url} alt={image3.altText} /> */}
            <StaticImage className={"vpimage3"} src="https://images.ctfassets.net/x5jqyuhgi4rx/2CkfRAEWeOXgAzBYJSL3RP/e9b96351f0965d6669be5624148b2ffa/third_value_prop_latest.png" layout="fullWidth" alt={image3.altText}/>
            {/* <div className="vpcontent3">
              <div className="vptitle3"><strong>{title3}</strong></div>
              <div className="vpsentence3">{bodyText3}</div>
            </div> */}
          </div>
        </div>

        <div className="vpitemsMQ">
          <div className="vpitemMQ">
          <StaticImage className={"vpimageMQ"} src="https://images.ctfassets.net/x5jqyuhgi4rx/4X3kBSv0Gb0ui7vs8rJ1jj/73ad8b3c1f12bfe14d9e5ac690bde857/first_value_prop_latest.png" layout="fullWidth" alt={image.altText}/>
            {/* <img className="vpimageMQ" src={image.image.file.url} alt={image.altText} /> */}
          </div>
          <div className="vpitemMQ">
          <StaticImage className={"vpimage2MQ"} src="https://images.ctfassets.net/x5jqyuhgi4rx/5lkVzkEMFThQZTZKQTSXWH/e4ab2b95c42e2c3495bed09ee5c26f50/second_value_prop_latest.png" layout="fullWidth" alt={image2.altText}/>
            {/* <img className="vpimage2MQ" src={image2.image.file.url} alt={image2.altText} /> */}
          </div>
          <div className="vpitemMQ">
          <StaticImage className={"vpimage3MQ"} src="https://images.ctfassets.net/x5jqyuhgi4rx/2CkfRAEWeOXgAzBYJSL3RP/e9b96351f0965d6669be5624148b2ffa/third_value_prop_latest.png" layout="fullWidth" alt={image3.altText}/>
            {/* <img className="vpimage3MQ" src={image3.image.file.url} alt={image3.altText} /> */}
          </div>
        </div>
        
      </div>
    </div>
  );
}


import React, { useEffect } from "react";
import "../scss/HomePageFeature1.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"

export default function HomePageFeature1() {
  const data = useStaticQuery(graphql`
    query MyQuery13 {
      allContentfulFlexBoxStyle(filter: { id: { eq: "6c9c6c90-fa4c-594d-ab25-f8fc62ba137a" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  if (data.allContentfulFlexBoxStyle.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const featureData = data.allContentfulFlexBoxStyle.edges[0].node;

  return (
    <>
      <Helmet>
        <h2>Be Empowered with Actionable Investment and Business Insights</h2>
      </Helmet>
    <div className="contentFeature1">
      <div data-aos="fade-up" className="boardTextContainer1 fade-in">
        <div className={"textContainer1"}>
          <p className={"title1"}>{featureData.title}</p>
          <p className={"categoryName1"}>{featureData.subTitle}</p>
          <style>{`
              .bodyText1 br {
                display: block;
                margin: 1em 0;
                content: "";
              }
            `}</style>
          <p className={"bodyText1"}>
            {documentToReactComponents(
              JSON.parse(featureData.bodyText.raw),
              options
            )}
          </p>
          <div className={"callToAction1"}>
            <Link
              to="/about/"
              style={{ textDecoration: "underline", color: "white", fontFamily: "'AriaText-SemiBold', sans-serif" }}
            >
              {featureData.callToAction}
            </Link>
          </div>
        </div>
        <div className={"displayBoardContainer1"}>
          <div className="displayBoard1"></div>
        </div>
      </div>
      <div className={"displayBarContainer1"}>
        <div data-aos="fade-up" className="displayBar1" />
      </div>
      <div className={"imageContainer1"}>
        {/* <img
          data-aos="fade-up"
          className={"bigImage"}
          alt={featureData.image.altText}
          key={""}
          src={featureData.image.image.file.url}
        /> */}
                {/* <StaticImage
        data-aos="fade-up"
        className={"bigImage"}
        src="https://images.ctfassets.net/x5jqyuhgi4rx/Mbx6wPu0JHA4ZUoI3Wi3K/27c9cc0d809d3435a7a2eae04ad50285/icon_image.png"
        alt={featureData.image.altText}
        layout="fullWidth" // Try different layouts to see which fits best
      /> */}
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/Mbx6wPu0JHA4ZUoI3Wi3K/27c9cc0d809d3435a7a2eae04ad50285/icon_image.png" layout="fullWidth" alt={featureData.image.altText}/>
      </div>
    </div>
    </>
  );
}


import React, { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../scss/HomePageQuote.scss';
import bof from './bof.png';

export default function HomePageIntro() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <div className="contentFeatureQuote">
      <div className="textContainerQuote">
        <div className="openQuote">“</div>
        <div className="quoteBody">To power up e-commerce growth, the digital customer experience and behavioural insights are priorities for data and analytics according to fashion executives</div>
        <div className="closeQuote">”</div>
        <img
          // data-aos="fade-up"
          className={"bof"}
          src={bof}
          alt="bof"
        />
      </div>
    </div>
  );
}













// import React, { useEffect, useState } from 'react';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import { graphql, useStaticQuery } from 'gatsby';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
// import '../scss/HomePageQuote.scss';
// import bof from './bof.png';
// import emailImage from './email_black.png'; // Import the 'email' image

// export default function HomePageIntro() {
//   const [showAlternateContent, setShowAlternateContent] = useState(false);

//   useEffect(() => {
//     Aos.init({ duration: 1000 });
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowAlternateContent((prev) => !prev);
//     }, 1000); // Change content after 1 second

//     return () => clearTimeout(timer); // Clean up timer on component unmount
//   }, [showAlternateContent]);

//   // Define your content variations
//   const originalContent = {
//     quoteText:
//       'To power up e-commerce growth, the digital customer experience and behavioural insights are priorities for data and analytics according to fashion executives',
//     image: bof,
//   };

//   const alternateContent = {
//     quoteText: 'I don’t have the luxury of time to be unhappy. I have too much to do. I have too much do accomplish. Who has the time to be unhappy?',
//     image: emailImage, // Use the 'email' image here
//   };

//   const content = showAlternateContent ? alternateContent : originalContent;

//   return (
//     <div className="contentFeatureQuote">
//       <div className="textContainerQuote">
//         <div className="openQuote">“</div>
//         <div className="quoteBody">{content.quoteText}</div>
//         <div className="closeQuote">”</div>
//         <img
//           // data-aos="fade-up"
//           className={'bof'}
//           src={content.image}
//           alt="bof"
//         />
//       </div>
//     </div>
//   );
// }


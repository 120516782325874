import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../scss/WhatIDoBar.scss";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

const WhatIDoBar = () => {
  const data = useStaticQuery(graphql`
    query MyQueryWhatIDo {
      allContentfulValuePropositionBar(filter: { id: { eq: "6ee1d145-e3ee-513d-add5-f0dabf3017b6" } }) {
        edges {
          node {
            bodyText
            bodyText2
            bodyText3
            title
            title2
            title3
            image3 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image2 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
            outsideTitle
          }
        }
      }
    }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;
  const [name3, description3] = bodyText3.split("Digital Transformation");
  const [name2, description2] = bodyText2.split("Reports");
  // const [name1, description1] = bodyText.split("Luxury");

  return (
    <>
      <Helmet>
        <h2>My Services</h2>
        <h3>luxury customer analysis, social listening and sentiment analysis</h3>
        <h3>luxury market insight and reports, data visualisation</h3>
        <h3>luxury keynote speaker, though leadership, luxury digital transformation</h3>
      </Helmet>
    <div className="contentFeatureWhatIDo">
      <div className="wbar">
        <div className="outsideTitle">My Services</div>
        <div className="witems">
          <div className="witem1">
          <Link to="/luxury-customer-sentimentanalysis">
            {/* <img className="wimage1" src={image.image.file.url} alt={image.altText} /> */}
            <StaticImage 
            className={"wimage1"} 
            src="https://images.ctfassets.net/x5jqyuhgi4rx/20vdyzPzJNs6IKnIbS9Hhh/0cb3a50f2d7270940f5252c1d1633de5/my_services_1.webp" 
            // layout="fullWidth" 
            alt={image.altText}/>
          </Link>
          </div>
          <div className="witem2">
            <Link to="/luxury-circulareconomy">
              {/* <img className="wimage2" src={image2.image.file.url} alt={image2.altText} /> */}
              <StaticImage 
              className={"wimage2"} 
              src="https://images.ctfassets.net/x5jqyuhgi4rx/5wyVxF90juXvxcyGtkxg6D/5b4bc5dcc221d8f5651e12bed38841a3/my_services_2.webp" 
              // layout="fullWidth" 
              alt={image2.altText}/>
            </Link>
          </div>
          <div className="witem3">
            <Link to="/luxury-keynotespeaker">
              {/* <img className="wimage3" src={image3.image.file.url} alt={image3.altText} /> */}
              <StaticImage 
              className={"wimage3"} 
              src="https://images.ctfassets.net/x5jqyuhgi4rx/uM0PSvIcq5YRA0Rb1NyHH/c5f4f1d9822ae2b6a79b064f6b492909/my_services_3.webp" 
              // layout="fullWidth" 
              alt={image3.altText}/>
            </Link>
          </div>
        </div>
        <div className="learnMore">Learn More</div>
      </div>
    </div>
    </>
  );
};

export default WhatIDoBar;











// import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
// import "../scss/WhatIDoBar.scss";

// const WhatIDoBar = () => {
//   const data = useStaticQuery(graphql`
//     query MyQueryWhatIDo {
//       allContentfulValuePropositionBar(filter: { id: { eq: "6ee1d145-e3ee-513d-add5-f0dabf3017b6" } }) {
//         edges {
//           node {
//             bodyText
//             bodyText2
//             bodyText3
//             title
//             title2
//             title3
//             image3 {
//               altText
//               image {
//                 file {
//                   url
//                 }
//               }
//             }
//             image2 {
//               altText
//               image {
//                 file {
//                   url
//                 }
//               }
//             }
//             image {
//               image {
//                 file {
//                   url
//                 }
//               }
//             }
//             id
//             outsideTitle
//           }
//         }
//       }
//     }
//   `);

//   const {
//     title,
//     title2,
//     title3,
//     bodyText,
//     bodyText2,
//     bodyText3,
//     image,
//     image2,
//     image3,
//   } = data.allContentfulValuePropositionBar.edges[0].node;
//   const [name3, description3] = bodyText3.split("Customers");
//   const [name2, description2] = bodyText2.split("Technology");
//   // const [name1, description1] = bodyText.split("Luxury");

//   return (
//     <div className="contentFeatureWhatIDo">
//       <div className="wbar">
//         <div className="outsideTitle">What I Do</div>
//         <div className="witems">
//           <div className="witem1">
//             <img className="wimage1" src={image.image.file.url} alt={image.altText} />
//             <div className="wText">
//               <div className="wtitle1"><strong>{title}</strong></div>
//               <div className="wsentence1">{bodyText}</div>
//             </div>
//           </div>
//           <div className="witem2">
//             <img className="wimage2" src={image2.image.file.url} alt={image2.altText} />
//             <div className="wText">
//               <div className="wtitle2"><strong>{title2}</strong></div>
//               <div className="wsentence2">
//                 {name2}
//                 <span className="highlight">Technology</span>
//                 <br />
//                 {description2}
//               </div>
//             </div>
//           </div>
//           <div className="witem3">
//             <img className="wimage3" src={image3.image.file.url} alt={image3.altText} />
//             <div className="wText">
//               <div className="wtitle3"><strong>{title3}</strong></div>
//               <div className="wsentence3">
//                 {name3}
//                 <span className="highlight">Customers</span>
//                 <br />
//                 {description3}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhatIDoBar;